// import { create } from "zustand";

// export const useStore = create((set) => ({
//   mapping_list: [],
//   setMappingList: (mapping_list) => set({ mapping_list }),

//   machine_list: [],
//   set_machine_list: (machine_list) => set({ machine_list }),

//   filter: false,
//   setFilter: (filter) => set({ filter }),

//   notify: { status: 0, message: "" },
//   setNotify: (notify) => set({ notify }),

//   open: false,
//   setOpen: (open) => set({ open }),

//   orderChoppingModal: false,
//   setOrderChoppingModal: (orderChoppingModal) => set({ orderChoppingModal }),

//   result: [],
//   set_result: (result) => set({ result }),

//   is_edit: false,
//   set_edit: (is_edit) => set({ is_edit }),

//   is_linked: false,
//   set_linked: (is_linked) => set({ is_linked }),

//   is_disabled: true,
//   set_disabled: (is_disabled) => set({ is_disabled }),

//   edit_data: {},
//   edit_machine_data: (edit_data) => set({ edit_data }),

//   mobile: "",
//   setMobile: (mobile) => set({ mobile })
// }));

import { create } from "zustand";

export const useStore = create((set) => ({
  mapping_list: [],
  setMappingList: (mapping_list) => set({ mapping_list }),

  new_mapping_list: false,
  setnewMappingList: (new_mapping_list) => set({ new_mapping_list }),

  machine_list: [],
  set_machine_list: (machine_list) => set({ machine_list }),

  filter: false,
  setFilter: (filter) => set({ filter }),

  notify: { status: 0, message: "" },
  setNotify: (notify) => set({ notify }),

  open: false,
  setOpen: (open) => set({ open }),

  orderChoppingModal: { status: false },
  setOrderChoppingModal: (orderChoppingModal) => set({ orderChoppingModal }),

  result: [],
  set_result: (result) => set({ result }),

  is_edit: false,
  set_edit: (is_edit) => set({ is_edit }),

  is_linked: false,
  set_linked: (is_linked) => set({ is_linked }),

  is_disabled: true,
  set_disabled: (is_disabled) => set({ is_disabled }),

  edit_data: {},
  edit_machine_data: (edit_data) => set({ edit_data }),

  mobile: "",
  setMobile: (mobile) => set({ mobile }),
}));
