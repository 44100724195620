import {
  faChevronLeft,
  faChevronRight,
  faLinkSlash,
  faTrashCan,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import {
  DELETE_MAPPING_URL,
  GET_MAPPING_URL,
  UPDATE_MAPPING_URL,
  GET_ORDER_CHOPPING,
} from "../Constants";
import axios from "../api/axios";
import { useStore } from "../store/Zustand";
import { useAuth } from "../store/auth";
import OrderChopping from "./OrderChopping";

export default function MappingData() {
  const token = useAuth((state) => state.token);
  const [mapping, setMapping] = useState([]);
  const new_mapping_list = useStore((state) => state.new_mapping_list);
  const setnewMappingList = useStore((state) => state.setnewMappingList);
  const [deleted, setDeleted] = useState(false);

  const filter = useStore((state) => state.filter);
  const mapping_list = useStore((state) => state.mapping_list);
  const is_linked = useStore((state) => state.is_linked);
  const set_linked = useStore((state) => state.set_linked);
  const setOrderChoppingModal = useStore(
    (state) => state.setOrderChoppingModal
  );
  const [get_file_name, set_file_name] = useState("");
  const [carefillId, setCarefillId] = useState("");

  // get all mapping
  const get_mapping = async () => {
    // console.log(token)
    await axios
      .get(GET_MAPPING_URL, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
        setMapping(response.data.data);
        // console.log(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          // resetAuth()
        } else {
          alert("user not logged in");
        }
        alert("Server is Down " + error);
      });
  };

  // handle_link request for mapping
  const handle_link = async (value) => {
    if (value.active === true) {
      // console.log("token", token)
      await axios
        .put(`${UPDATE_MAPPING_URL}/${value.id}`, null, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        })
        .then((response) => {
          set_linked(true);
        })
        .catch((error) => {
          alert("Server is Down " + error);
        });
    }
  };

  // delete request for mapping
  const handle_delete = async (id) => {
    await axios
      .delete(`${DELETE_MAPPING_URL}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      })
      .then((response) => {
        setDeleted(true);
      })
      .catch((error) => {
        if (error.status === 304) {
          alert("Please unlink it before deleting mapping");
        } else {
          alert("Server is Down " + error);
        }
      });
  };

  const handleSubmit = async (file_name, carefill_id) => {
    try {
      const response = await axios.get(`${GET_ORDER_CHOPPING}/${file_name}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        })
      // console.log(file_name)

      if (response.status === 200) {
        setOrderChoppingModal({ status: true, data: response.data });
        setnewMappingList(true);
        set_file_name(file_name);
        setCarefillId(carefill_id);
        // setDate("");
      } else {
        alert("Failed to split order [1]");
      }
    } catch (err) {
      // setDate("")
      if (err?.response?.data?.status_message) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 500) {
        alert("Failed to split order [2]");
      } else if (err.response.status === 401) {
        alert(err?.response?.data?.status_message);
        // console.log(err.response)
        alert(err.response.data.status_message);
      } else {
        alert("Failed to split order [3]");
      }
    }
  };

  // Pagination code

  const [active, setActive] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRecord = currentPage * 10;
  const indexOfFirstRecord = indexOfLastRecord - 10;
  const currentRecords = mapping.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(mapping.length / 10);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  // ----------------------------------------------

  useEffect(() => {
    setnewMappingList(false);
    get_mapping();
    setDeleted(false);
    set_linked(false);
  }, [mapping_list, deleted, filter, is_linked, token, new_mapping_list]);

  return (
    <div className="w-full px-10 py-5">
      <Card className="h-full w-full">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Sr. no.
              </th>
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Carefill Id
              </th>
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Machine Id
              </th>
              {/* <th className="text-center p-4 font-normal leading-none opacity-70">
                Accessed
              </th> */}
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Operator Name
              </th>
              <th className="text-center p-4 font-normal leading-none opacity-70">
                File Name
              </th>
              {/* <th className="text-center p-4 font-normal leading-none opacity-70">
                Active
              </th> */}
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Date
              </th>
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Time
              </th>
              <th className="text-center p-4 font-normal leading-none opacity-70">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.length === 0 && (
              <tr>
                <td className="p-2">Data Not Found</td>
              </tr>
            )}

            {/* ------------------- filter === false --------------------------- */}

            {currentRecords.length > 0 &&
              !filter &&
              currentRecords.map((value, index) => {
                return (
                  <tr
                    className={
                      value.active ? `bg-green-400` : `hover:bg-gray-200`
                    }
                    key={index}
                  >
                    <td className="text-center p-5">
                      {indexOfFirstRecord + index + 1}
                    </td>
                    <td className="text-center p-2">{value.carefill_id}</td>
                    <td className="text-center p-2">{value.machine_number}</td>
                    {/* <td className="text-center p-2">{value.accessed}</td> */}
                    <td className="text-center p-2">{value.operator_name}</td>
                    <td className={value.file_name.includes("-splitted") ? `text-blue-800 font-semibold` : `hover:bg-gray-200`}>{value.file_name}</td>
                    {/* <td className="text-center p-2">{value.file_name}</td> */}
                    {/* <td className="text-center p-2">
                      {value.active ? "True" : "False"}
                    </td> */}
                    <td className="text-center p-2">{value.Date}</td>
                    <td className="text-center p-2">{value.Time}</td>
                    <td className="text-center p-2 cursor-pointer flex items-center justify-evenly">
                      <Button
                        className="bg-white p-3 border border-gray-400 mx-1 mb-2"
                        onClick={() => {
                          handleSubmit(value.file_name, value.carefill_id)
                        }}
                      >
                        <FontAwesomeIcon
                          className="text-orange-500 h-4 w-4"
                          icon={faProjectDiagram}
                        />
                      </Button>
                      <Button
                        className="bg-white p-3 border border-gray-400 mx-1"
                        onClick={() => {
                          handle_link(value);
                        }}
                      >
                        <FontAwesomeIcon
                          className={
                            value.active
                              ? `text-blue-800 h-4 w-4`
                              : `text-orange-500 h-4 w-4`
                          }
                          icon={faLinkSlash}
                        />
                      </Button>

                      <Button
                        className="bg-white p-3 border border-gray-400 mx-1"
                        onClick={() => {
                          handle_delete(value.id);
                        }}
                      >
                        <FontAwesomeIcon
                          className="text-orange-500 h-4 w-4"
                          icon={faTrashCan}
                        />
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {/* --------------------- filter === true --------------------------- */}

            {currentRecords.length > 0 &&
              filter &&
              mapping.map((value, index) => {
                return (
                  value.active && (
                    <tr
                      className={
                        value.active ? `bg-green-400` : `hover:bg-gray-200`
                      }
                      key={index}
                    >
                      <td className="text-center p-5">
                        {indexOfFirstRecord + index + 1}
                      </td>
                      <td className="text-center p-2">{value.carefill_id}</td>
                      <td className="text-center p-2">
                        {value.machine_number}
                      </td>
                      {/* <td className="text-center p-2">{value.accessed}</td> */}
                      <td className="text-center p-2">{value.operator_name}</td>
                      <td className="text-center p-2">{value.file_name}</td>
                      {/* <td className="text-center p-2">
                        {value.active ? "True" : "False"}
                      </td> */}
                      <td className="text-center p-2">{value.Date}</td>
                      <td className="text-center p-2">{value.Time}</td>
                      <td className="text-center p-2 cursor-pointer flex items-center justify-evenly">
                        <Button
                          className="bg-white p-3 border border-gray-400 mx-1"
                          onClick={() => {
                            handle_link(value);
                          }}
                        >
                          <FontAwesomeIcon
                            className={
                              value.active
                                ? `text-blue-800 h-4 w-4`
                                : `text-orange-500 h-4 w-4`
                            }
                            icon={faLinkSlash}
                          />
                        </Button>

                        <Button
                          className="bg-white p-3 border border-gray-400 mx-1"
                          onClick={() => {
                            handle_delete(value.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-orange-500 h-4 w-4"
                            icon={faTrashCan}
                          />
                        </Button>
                      </td>
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </Card>

      <div className="flex items-center justify-around gap-8 my-6">
        <Button
          className="px-4"
          onClick={() => {
            if (currentPage !== 1) setCurrentPage(currentPage - 1);
            setActive(active - 1);
          }}
          disabled={active === 1}
        >
          {/* Prev */}
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>

        <ul
          color="gray"
          className="font-normal flex items-center justify-around"
        >
          {!filter ? (
            pageNumbers.map((value, index) => {
              return (
                <li
                  key={index}
                  className={
                    currentPage === value
                      ? "cursor-pointer px-2 border border-gray-500 text-white bg-gray-900"
                      : "cursor-pointer px-2 border border-gray-500"
                  }
                >
                  <strong
                    className={
                      currentPage === value ? "text-white" : "text-gray-900"
                    }
                  >
                    {value}
                  </strong>
                </li>
              );
            })
          ) : (
            <li className="cursor-pointer px-2 border border-gray-500 text-white bg-gray-900">
              <strong className="text-white">1</strong>
            </li>
          )}
        </ul>

        <Button
          className="px-4"
          onClick={() => {
            if (currentPage !== nPages) setCurrentPage(currentPage + 1);
            setActive(active + 1);
          }}
          disabled={nPages === currentPage || filter}
        >
          {/* Next */}
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
      <div className="w-full max-h-min">
        <OrderChopping file_name={get_file_name} carefill_id={carefillId} />
      </div>
    </div>
  );
}
