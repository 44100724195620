export const LOGIN_URL = "/login";
export const SET_MAPPING_URL = "/mapping/set";
export const GET_MAPPING_URL = "/mapping/get";
export const DELETE_MAPPING_URL = "/mapping/delete";
export const UPDATE_MAPPING_URL = "/mapping/update";
export const CREATE_MACHINE_URL = "/machine/create";
export const GET_MACHINE_URL = "/machine/get";
export const UPDATE_MACHINE_URL = "/machine/update";
export const UPDATE_MACHINE_STATUS_URL = "/machine/update/status";
export const DELETE_MACHINE_URL = "/machine/delete";
export const GENERATE_OTP = "/machine/signup/otp";
export const LOGIN_OTP = "/machine/login/otp";
export const GET_ORDER_CHOPPING = "/mapping/chopping/get";
export const ORDER_CHOPPING = "/mapping/chopping/set";
